import { template as template_c8cf23300de14be5902e096449e0d45a } from "@ember/template-compiler";
const FKLabel = template_c8cf23300de14be5902e096449e0d45a(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
