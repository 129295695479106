import { template as template_5c4d98fc900f4442bf5b620b52215ad9 } from "@ember/template-compiler";
const FKControlMenuContainer = template_5c4d98fc900f4442bf5b620b52215ad9(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
